<template>
  <div>
    <validation-observer ref="simpleRules">

      <b-row>
        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2 d-flex justify-content-between align-items-center">
              <h3 class="mb-0">
                {{ $t('details') }} {{ WarehuseData.wh_name }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="3" />
                <b-col md="6">
                  <b-form-group
                    label-for="h-role-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('orderNumber') }}
                    </template>
                    <div class="mt-50">
                      {{ WarehuseData.wh_name }}
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="3" />
                <b-col md="3" />
                <b-col md="6">
                  <b-form-group
                    label-for="h-role-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('remarks') }}
                    </template>
                    <b-form-textarea
                      v-model="WarehuseData.remark"
                      rows="3"
                      @change="UpdataRemark()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" />
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2 d-flex justify-content-between align-items-center">
              <h3 class="mb-0">
                {{ $t('selectProductLot') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label-for="h-role-name"
                    label-cols="4"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('selectProductLot') }}
                    </template>
                    <div class="btn-lot mt-n50">
                      <button
                        class="btn-select-lot"
                        @click="selectLot()"
                      >
                        {{ $t('selectProductLot') }}
                      </button>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="3" />

                <b-col md="3" />
                <b-col md="6">
                  <b-form-group
                    v-if="WarehuseData.wh_lot && WarehuseData.wh_lot.length > 0"
                    label-for="h-role-name"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('selectedLot') }}
                    </template>
                    <div class="selct-lot mt-50">
                      <router-link
                        v-for="(item,index) in WarehuseData.wh_lot"
                        :key="item._id"
                        :to="{name : 'admin-warehouse-edit' , params: { id: item.lot_name }}"
                      >
                        {{ item.lot_name }} {{ (WarehuseData.wh_lot.length - 1) > index ? ',':'' }}
                      </router-link>
                      <div>
                        <b-button
                          size="sm"
                          class="btn-gradient-danger"
                          @click="CancelWarehuseData()"
                        >
                          ยกเลิกคำนวณชั้นวาง
                        </b-button>
                      </div>

                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="3" />
              </b-row>

              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="lotitems"
                :fields="lotfields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                show-empty
                :tbody-tr-class="rowClass"
              >
                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>

                <template #cell(manage)="data">
                  <i
                    class="far fa-check"
                    @click="CollocateTH(data.item, false)"
                  />
                </template>

                <template #cell(queue)="data">
                  {{ data.item.width && data.item.long && data.item.height ?
                    CommasCue(data.item.charge_cue*data.item.product_amount) : 0 }}
                </template>

                <!-- <template #cell(weight)="data">
                  {{ data.item.weight_cn = data.item.weight }}
                </template> -->

                <template #cell(actions)="data">
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-1 text-primary cursor-pointer"
                    @click="EditCollCn(data.item)"
                  />
                </template>
              </b-table>
              <div class="d-flex align-items-center justify-content-start p-2">
                <!-- <span class="czx ctx-yellow">
                  {{ $t('checkGoods') }}
                </span> -->
                <span class="czx ctx-green">
                  {{ $t('checkOrder') }}
                </span>
                <span class="czx ctx-orange">
                  {{ $t('key-8') }}
                </span>
                <span class="czx ctx-red">
                  {{ $t('noPrice') }}
                </span>
                <span class="czx ctx-purple">
                  {{ $t('claim') }}
                </span>
              </div>
            </div>
          </div>
        </b-col>

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('receivedItemList') }}
              </h3>
            </div>

            <b-row class="mt-1 px-2">
              <!-- <b-col md="4" /> -->
              <b-col md="4 mb-1">
                <div class="d-flex">
                  <p class="mb-0 mr-1">
                    {{ $t('selectedLot') }}
                  </p>
                  <div class="selct-lot">
                    <router-link
                      v-for="(item,index) in WarehuseData.wh_lot"
                      :key="item._id"
                      :to="{name : 'admin-warehouse-edit' , params: { id: item.lot_name }}"
                    >
                      {{ item.lot_name }} {{ (WarehuseData.wh_lot.length - 1) > index ? ',':'' }}
                    </router-link>
                  </div>
                </div>
              </b-col>
              <b-col md="8" />

              <!-- <b-col md="3" /> -->
              <b-col md="6">
                <div class="d-flex align-items-center">
                  <b-form-group class="w-75">
                    <validation-provider
                      #default="{ errors }"
                      name="Parcel Code"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <i class="fal fa-barcode-read" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="h-parcel-code"
                          v-model="parcelCode"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-button
                    variant="primary"
                    class="ml-50 w-sm-75 w-md-50 w-lg-50 mt-n1"
                    @click="addParcelCode()"
                  >
                    {{ $t('key-12') }}
                  </b-button>
                </div>
              </b-col>
              <b-col md="8" />
            </b-row>

            <div class="p-2 mt-n2">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="incomeItems"
                :fields="incomefields"
                show-empty
                :tbody-tr-class="rowClass"
              >
                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>

                <template #cell(queue)="data">
                  {{ data.item.width && data.item.long && data.item.height ? CommasCue((data.item.charge_cue)* data.item.product_amount): 0 }}
                </template>

                <template #cell(weight)="data">
                  <span :class="`${data.item.weight_cn !== data.item.weight_th ? 'text-danger': ''}`">{{ data.item.weight_cn }}</span>
                </template>
                <template #cell(weight_th)="data">
                  <span :class="`${data.item.weight_cn !== data.item.weight_th ? 'text-danger': ''}`">{{ data.item.weight_th }}</span>
                </template>
                <!-- <template #cell(lot)="data">
                  <small>{{ data.item.id }}LOT1234</small>
                </template> -->

                <template #cell(manage)="data">
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-1 text-primary cursor-pointer"
                    @click="CollocateTH(data.item, true)"
                  />
                </template>
              </b-table>
              <div class="d-flex align-items-center justify-content-start p-2">
                <!-- <span class="czx ctx-yellow">
                  {{ $t('checkGoods') }}
                </span> -->
                <span class="czx ctx-green">
                  {{ $t('checkOrder') }}
                </span>
                <span class="czx ctx-orange">
                  {{ $t('key-8') }}
                </span>
                <span class="czx ctx-red">
                  {{ $t('noPrice') }}
                </span>
                <span class="czx ctx-purple">
                  {{ $t('claim') }}
                </span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </validation-observer>

    <!-- Modal Lot modal-lot-->
    <b-modal
      ref="modal-lot"
      :title="$t('productLot')"
      :ok-title="$t('key-13')"
      :cancel-title="$t('cancel')"
      size="lg"
      @ok="UpdateLot()"
    >
      <b-row>
        <b-col md="6" />
        <b-col md="6">
          <b-form-group
            label-for="h-section"
            label-cols="3"
            label-cols-lg="3"
            label-cols-md="3"
          >
            <!-- {{ section }} -->
            <template v-slot:label>
              {{ $t('arrange') }} :
            </template>
            <v-select
              id="h-section"
              v-model="section"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="sectionlist"
              class="w-75 mr-1"
              :reduce="text => text._id"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="d-flex align-items-center">
        <div class="w-45">
          <div class="card border">
            <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  v-model="checkAll"
                  value="true"
                  class="custom-control-primary"
                  :indeterminate="isIndeterminate"
                  @change="checkAllOptions"
                />
                <h6 class="mb-0">
                  <span v-if="roleDetail.length">{{ roleDetail.length }}/</span>{{ optionsLeft.length }} {{ $t('item')
                  }}
                </h6>
              </div>

              <h6 class="mb-0">
                {{ $t('listlot') }}
              </h6>
            </div>

            <div class="p-1">
              <div class="income-order-search order-search">
                <div class="anan-input search-input">
                  <div class="anan-input__inner anan-input__inner--normal">
                    <input
                      v-model="searchLeft"
                      type="text"
                      placeholder="Search here..."
                      class="anan-input__input"
                      @input="OptionssearchLeft"
                    >
                    <div class="anan-input__suffix">
                      <i class="anan-input__clear-btn anan-icon" />
                      <i class="anan-input__suffix-icon anan-icon">
                        <i class="fal fa-search" />
                      </i>
                    </div>
                  </div>
                </div>
              </div>

              <div class="role-items">
                <b-form-group class="mt-1">
                  <b-form-checkbox
                    v-for="option in filteredOptions"
                    :key="option.name_lot"
                    v-model="roleDetail"
                    :value="option._id"
                    name="flavour-3a"
                  >
                    <div class="mb-1">
                      {{ option.transportType === 1 ? $t('truck') : $t('ship') }}/{{ option.name_lot }}
                    </div>
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

        <div class="w-10 p-50">
          <div>
            <button
              type="button"
              class="btn btn-primary btn-sm btn-icon-only w-100 mb-1"
              @click="moveToLeft"
            >
              <i class="fas fa-arrow-to-left" />
            </button>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-primary btn-sm btn-icon-only w-100"
              @click="moveToRight"
            >
              <i class="fas fa-arrow-from-left" />
            </button>
          </div>
        </div>

        <div class="w-45">
          <div class="card border">
            <div class="header-cardx p-1 d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  v-model="checkAll2"
                  value="true"
                  class="custom-control-primary"
                  :indeterminate="isIndeterminate2"
                  @change="checkAllOptions"
                />
                <h6 class="mb-0">
                  <span v-if="roleDetail2.length">{{ roleDetail2.length }}/</span>{{ optionsRight.length }} {{
                    $t('item') }}
                </h6>
              </div>

              <h6 class="mb-0">
                {{ $t('selectlot') }}
              </h6>
            </div>

            <div class="p-1">
              <div class="income-order-search order-search">
                <div class="anan-input search-input">
                  <div class="anan-input__inner anan-input__inner--normal">
                    <input
                      v-model="searchRight"
                      type="text"
                      placeholder="Search here..."
                      class="anan-input__input"
                    >
                    <div class="anan-input__suffix">
                      <i class="anan-input__clear-btn anan-icon" />
                      <i class="anan-input__suffix-icon anan-icon">
                        <i class="fal fa-search" />
                      </i>
                    </div>
                  </div>
                </div>
              </div>

              <div class="role-items">
                <b-form-group class="mt-1">
                  <b-form-checkbox
                    v-for="option in filteredOptions2"
                    :key="option.name_lot"
                    v-model="roleDetail2"
                    :value="option._id"
                    name="flavour-3a"
                  >
                    <div class="mb-1">
                      {{ option.transportType === 1 ? $t('truck') : $t('ship') }}/{{ option.name_lot }}
                    </div>
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="modal-collocate"
      :title="$t('key-14')"
      :ok-title="$t('key-13')"
      :cancel-title="$t('cancel')"
      size="sm"
      @ok="UpdateCollocate()"
    >
      <div class="d-flex align-items-center">
        <div class="w-100">
          <div class="d-flex align-items-center pt-1">
            <div class="w-50 text-right">
              <span class="font-weight-bolder pr-1">
                {{ $t('customerCode') }} :
              </span>
            </div>
            <div class="w-50">
              {{ CollocatePre.username }}
            </div>
          </div>

          <div class="d-flex align-items-center pt-1">
            <div class="w-50 text-right">
              <span class="font-weight-bolder pr-1">
                {{ $t('key-15') }} :
              </span>
            </div>
            <div class="w-50">
              {{ lotitems.filter(item => item.username === CollocatePre.username).length }}
            </div>
          </div>

          <div class="d-flex align-items-center pt-1">
            <div class="w-50 text-right">
              <span class="font-weight-bolder pr-1">
                {{ $t('arrange') }} :
              </span>
            </div>
            <div class="w-50">
              <v-select
                id="h-section"
                v-model="sectionEdit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="sectionlist"
                class="w-75 mr-1"
                :reduce="text => text._id"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="modal-collocate-th"
      :title="$t('key-16')"
      :ok-title="$t('key-13')"
      :cancel-title="$t('cancel')"
      size="sm"
      @ok="UpdateCollocateTH()"
    >

      <div class="w-100">
        <div class="d-flex align-items-center pt-1">
          <div class="w-50 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('parcelCode') }} :
            </span>
          </div>
          <div class="w-50">
            {{ CollocatePreTH.postnumber }}
          </div>
        </div>

        <div class="d-flex align-items-center pt-1">
          <div class="w-50 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('customerCode') }} :
            </span>
          </div>
          <div class="w-50">
            {{ CollocatePreTH.username }}
          </div>
        </div>
        <div class="d-flex align-items-center pt-1">
          <div class="w-50 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('arrange') }} :
            </span>
          </div>
          <div class="w-50">
            {{ CollocatePreTH.collocate_wh_name }}
          </div>
        </div>
        <div class="d-flex align-items-center pt-1">
          <div class="w-50 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('quantity') }} :
            </span>
          </div>
          <div class="w-50">
            {{ CollocatePreTH.product_amount }}
          </div>
        </div>
        <div class="d-flex align-items-center pt-1">
          <div class="w-50 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('key-17') }} :
            </span>
          </div>
          <div class="w-50">
            {{ Commas(CollocatePreTH.weight_cn) }} KG
          </div>
        </div>
        <div class="d-flex align-items-center pt-1">
          <div class="w-50 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('weightThailand') }} :
            </span>
          </div>
          <div class="w-50">
            <b-input-group
              class="input-group-merge w-75"
              append="KG"
            >
              <b-form-input
                id="weight_th"
                v-model="CollocatePreTH.weight_th"
                type="text"
                :placeholder="$t('weightThailand')"
              />
            </b-input-group>
          </div>
        </div>
        <div class="d-flex align-items-center pt-1">
          <div class="w-50 text-right">
            <span class="font-weight-bolder pr-1">
              {{ $t('key-18') }} :
            </span>
          </div>
          <div class="w-50">
            {{ CollocatePreTH.width && CollocatePreTH.long && CollocatePreTH.height ?
              CommasCue(CollocatePreTH.charge_cue*CollocatePreTH.product_amount) : 0 }}
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { email, required } from '@validations'
import {
  BCol, BFormCheckbox, BFormGroup, BFormInput,
  BFormTextarea,
  BModal,
  BRow,
  BTable,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BModal,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sectionlist: [],
      section: '',
      sectionEdit: '',
      role: '',
      searchLeft: '',
      searchRight: '',
      checkAll: false,
      checkAll2: false,
      roleDetail: [],
      roleDetail2: [],
      optionsRight: [],
      optionsLeft: [],
      required,
      email,
      name: '',
      category: '',
      code: '',
      details: '',
      selectedFiles: [],
      uploadedImages: [],

      lotitems: [],

      incomeItems: [],
      ID: this.$route.params.id,
      WarehuseData: {},
      showOver: false,
      CollocatePre: {},
      CollocatePreTH: {},
      parcelCode: '',
    }
  },
  computed: {
    lotfields() {
      return [
        {
          key: 'manage', label: this.$t('manage'), thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'lot_name', label: this.$t('lot'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center',
        },
        {
          key: 'lot_order', label: this.$t('sequence'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'postnumber', label: this.$t('parcelCode'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center',
        },
        {
          key: 'come_chinesewarehouse', label: this.$t('enterWarehouse'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'out_chinesewarehouse', label: this.$t('warehouseExit'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'come_thaiwarehouse', label: this.$t('arrivedThailand'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'username', label: this.$t('customer'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'product_amount', label: this.$t('quantity'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center', format: value => this.Commas(value),
        },
        {
          key: 'weight', label: this.$t('weight'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center', format: value => this.Commas(value),
        },
        {
          key: 'queue', label: this.$t('cubic'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'collocate_wh_name', label: this.$t('arrange'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: '', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
    incomefields() {
      return [
        {
          key: 'manage', label: this.$t('manage'), thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'lot_name', label: this.$t('lot'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center',
        },
        {
          key: 'lot_order', label: this.$t('sequence'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'postnumber', label: this.$t('parcelCode'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center',
        },
        {
          key: 'come_chinesewarehouse', label: this.$t('enterWarehouse'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'out_chinesewarehouse', label: this.$t('warehouseExit'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'come_thaiwarehouse', label: this.$t('arrivedThailand'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'username', label: this.$t('customer'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'product_amount', label: this.$t('quantity'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center', format: value => this.Commas(value),
        },
        {
          key: 'weight', label: this.$t('weight'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center', format: value => this.Commas(value),
        },
        {
          key: 'weight_th', label: this.$t('weightThailand'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center', format: value => this.Commas(value),
        },
        {
          key: 'queue', label: this.$t('cubic'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'collocate_wh_name', label: this.$t('arrange'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      // {
      //   key: 'actions', label: '', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
      // },
      ]
    },
    isIndeterminate() {
      return this.roleDetail.length > 0 && this.roleDetail.length < this.optionsLeft.length
    },
    filteredOptions() {
      if (this.searchLeft) {
        const searchTerm = this.searchLeft.toLowerCase()
        return this.optionsLeft.filter(option => option.name_lot.toLowerCase().includes(searchTerm))
      }
      return this.optionsLeft
    },
    isIndeterminate2() {
      return this.roleDetail2.length > 0 && this.roleDetail2.length < this.optionsRight.length
    },
    filteredOptions2() {
      if (this.searchRight) {
        const searchTerm2 = this.searchRight.toLowerCase()
        return this.optionsRight.filter(option => option.name_lot.toLowerCase().includes(searchTerm2))
      }
      return this.optionsRight
    },
  },
  mounted() {
    this.getLotOp()
    this.getSection()
  },
  methods: {
    CancelWarehuseData() {
      this.$bvModal
        .msgBoxConfirm('ต้องการยกเลิกคำนวณชั้นวาง ?', {
          title: 'ยืนยันการทำรายการ',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'ตกลง',
          cancelTitle: 'ยกเลิก',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }).then(result => {
          if (result) {
            const obj = {
              ID: this.ID,
            }
            this.$http.post('/warehouseTH/ResetWarehouseTh', obj)
              .then(() => {
                this.Alert(true, 'ยกเลิกคำนวณชั้นวางเรียบร้อย')
                this.getLotOp()
                this.getSection()
              })
              .catch(err => {
                console.log(err)
                this.Alert(false, 'ยกเลิกคำนวณชั้นวางไม่สำเร็จ')
              })
          }
        })
    },
    UpdateCollocateTH() {
      this.showOver = true
      this.CollocatePreTH.warehouseTH_checked = true
      this.CollocatePreTH.ID = this.ID
      this.$http.post('/warehouseTH/UpdateOrderPost', this.CollocatePreTH)
        .then(() => {
          const obj = {
            ID: this.ID,
            wh_lot: this.WarehuseData.wh_lot,
          }
          this.$http.post('/warehouseTH/updatepost', obj)
            .then(() => {
              this.GetData()
              this.showOver = false
              this.makeToast('success', this.$t('key-16'), this.$t('key-269'))
              // eslint-disable-next-line no-underscore-dangle
              // const IndexCn = this.lotitems.findIndex(item => item._id === this.CollocatePreTH._id)
              // this.incomeItems.unshift(this.lotitems[IndexCn])
              // this.lotitems.splice(IndexCn, 1)
            })
            .catch(() => {
              this.makeToast('danger', this.$t('key-16'), this.$t('key-270'))
              this.showOver = false
            })
        })
        .catch(() => {
          this.makeToast('danger', this.$t('key-16'), this.$t('key-270'))
          this.showOver = false
        })
    },
    CollocateTH(item, type) {
      this.CollocatePreTH = item
      if (!item.weight_th || item.weight_th === 0) {
        this.CollocatePreTH.weight_th = item.weight
      }
      if (type) {
        this.CollocatePreTH.weight_cn = item.weight_cn
      } else {
        this.CollocatePreTH.weight_cn = item.weight
      }

      this.$refs['modal-collocate-th'].show()
    },
    UpdateCollocate() {
      this.showOver = true
      // eslint-disable-next-line no-underscore-dangle
      const SecJson = this.sectionlist.find(item => item._id === this.sectionEdit)
      // eslint-disable-next-line no-underscore-dangle
      this.CollocatePre.collocate_wh_id = SecJson._id
      this.CollocatePre.collocate_wh_name = SecJson.name
      this.$http.post('/postnumber/update', this.CollocatePre)
        .then(() => {
          this.showOver = false
          this.makeToast('success', this.$t('key-14'), this.$t('key-271'))
        })
        .catch(() => {
          this.makeToast('danger', this.$t('key-14'), this.$t('key-272'))
          this.showOver = false
        })
    },
    EditCollCn(item) {
      this.CollocatePre = item
      this.sectionEdit = item.collocate_wh_id
      this.$refs['modal-collocate'].show()
    },
    getSection() {
      this.$http.get('/warehouses/getlist').then(response => {
        this.sectionlist = response.data.data
      })
    },
    UpdateLot() {
      if (this.optionsRight.length === 0 || !this.section) {
        this.showOver = false
        this.makeToast('danger', this.$t('key-20'), this.$t('key-273'))
        return
      }
      this.showOver = true

      const LotJson = Object.values(this.optionsRight).map(item => ({
        // eslint-disable-next-line no-underscore-dangle
        lot_id: item._id,
        lot_name: item.name_lot,
        lot_number: item.number_lot,
      }))
      this.WarehuseData.wh_lot = LotJson
      // eslint-disable-next-line no-underscore-dangle
      const SecJson = this.sectionlist.find(item => item._id === this.section)
      const obj = {
        ID: this.ID,
        wh_lot: LotJson,
        wh_section: SecJson,
      }
      this.$http.post('/warehouseTH/updatelot', obj)
        .then(() => {
          // eslint-disable-next-line no-underscore-dangle
          // const LotID = Object.values(this.optionsRight).map(item => item._id)
          this.ShowPost(this.ID)
        })
        .catch(() => {
          this.showOver = false
        })
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return ''
      if (item.packbox && item.checkproduct) {
        return 'ctx-yellow'
      } if (item.checkproduct) {
        return 'ctx-green'
      } if (item.packbox) {
        return 'ctx-orange'
      } if (item.charge_per_save === 0) {
        return 'ctx-red'
      } if (item.claim_status) {
        return 'ctx-purple'
      }
      return ''
    },
    async ShowPost(LotID) {
      const obj2 = {
        ID: LotID,
      }
      this.$http.post('/warehouseTH/showpost', obj2)
        .then(response => {
          this.lotitems = response.data.data.Post_cn
          this.incomeItems = response.data.data.Post_th
          // this.lotitems.forEach(element => {
          //   // eslint-disable-next-line
          //   element._rowVariant = 'danger'
          // })
          // this.showOver = false
        })
        .catch(() => {
          this.showOver = false
        })
    },
    async getLotOp() {
      try {
        const { data: res } = await this.$http.get('/warehouseTH/show/listLot')
        this.optionsLeft = res.data
        this.GetData()
      } catch (e) {
        console.log(e)
      }
    },
    async GetData() {
      try {
        const params = {
          ID: this.ID,
        }
        const { data: res } = await this.$http.get('/warehouseTH/show', { params })
        this.WarehuseData = res.data
        const LotID = Object.values(this.WarehuseData.wh_lot).map(item => item.lot_id)
        // eslint-disable-next-line no-underscore-dangle
        // console.debug(LotID)
        // this.optionsRight = this.WarehuseData.filter(option => LotID.includes(option._id))
        // eslint-disable-next-line no-underscore-dangle
        this.RightLot(LotID)
        // this.optionsLeft = this.optionsLeft.filter(option => !LotID.includes(option._id))
        // eslint-disable-next-line no-underscore-dangle
        // const LotIDArr = Object.values(this.optionsLeft).map(item => item._id)
        // console.debug(LotIDArr)
        this.ShowPost(this.ID)
      } catch (error) {
        console.log(error)
      }
    },
    RightLot(LotID) {
      const params = {
        ID: LotID,
      }
      this.$http.get('/warehouseTH/RightLot', { params })
        .then(response => {
          this.optionsRight = response.data.data
        })
    },
    addParcelCode() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.showOver = true
          // eslint-disable-next-line no-underscore-dangle
          const IndexCn = this.lotitems.findIndex(item => item.postnumber === this.parcelCode)
          if (IndexCn !== -1) {
            const Collocate = this.lotitems[IndexCn]
            this.CollocateTH(Collocate, false)
            this.parcelCode = ''
          } else {
            this.makeToast('danger', this.$t('key-16'), this.$t('key-274'))
            this.showOver = false
          }
        }
      })
    },
    OptionssearchLeft() {
      if (this.searchLeft) {
        this.filteredOptionsLeft()
      }
      // return this.optionsLeft
    },
    OptionssearchLeft() {
      if (this.searchRight) {
        this.filteredOptionsLeft()
      }
      // return this.optionsLeft
    },
    selectLot() {
      this.$refs['modal-lot'].show()
    },
    checkAllOptions() {
      if (this.checkAll) {
        this.roleDetail = this.optionsLeft.map(option => option._id)
      } else {
        this.roleDetail = []
      }
      if (this.checkAll2) {
        this.roleDetail2 = this.optionsRight.map(option => option._id)
      } else {
        this.roleDetail2 = []
      }
    },
    moveToLeft() {
      if (!this.section) {
        this.makeToast('warning', this.$t('key-20'), this.$t('key-275'))
        return
      }
      // eslint-disable-next-line no-underscore-dangle
      this.optionsLeft = this.optionsLeft.concat(this.optionsRight.filter(option => this.roleDetail2.includes(option._id)))
      // eslint-disable-next-line no-underscore-dangle
      this.optionsRight = this.optionsRight.filter(option => !this.roleDetail2.includes(option._id))
      this.roleDetail2 = []
      this.checkAll = false
      this.checkAll2 = false
    },
    moveToRight() {
      if (!this.section) {
        this.makeToast('warning', this.$t('key-20'), this.$t('key-275'))
        return
      }
      // eslint-disable-next-line no-underscore-dangle
      this.optionsRight = this.optionsRight.concat(this.optionsLeft.filter(option => this.roleDetail.includes(option._id)))
      // eslint-disable-next-line no-underscore-dangle
      this.optionsLeft = this.optionsLeft.filter(option => !this.roleDetail.includes(option._id))
      this.roleDetail = []
      this.checkAll = false
      this.checkAll2 = false
    },
    removeItem(level) {
      this.optionsRight = this.optionsRight.filter(option => option.level !== level)
    },
    addAddr() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          this.$bvModal.hide('add-addr')
        }
      })
    },
    showAddAddr() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.$bvModal.show('modal-1')
    },
    async UpdataRemark() {
      try {
        const obj = {
          ID: this.ID,
          remark: this.WarehuseData.remark,
        }
        await this.$http.post('/warehouseTH/updateremark', obj)
        this.makeToast('success', this.$t('key-276'), this.$t('key-277'))
      } catch (error) {
        console.log(error)
        this.makeToast('danger', this.$t('key-276'), this.$t('key-278'))
      }
    },
    makeToast(variant = null, title, mes) {
      this.$bvToast.toast(mes, {
        title,
        variant,
        solid: true,
      })
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'success' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
